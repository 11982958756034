<template>
  <div class='template'>  
    <div class='images'>
        <AttachmentUI :page="page" :gallery="gallery" @showGallery="galleryVisible = true" @showVideo="showVideo"/>
        <transition name="fade" appear>
            <img :src="page?.background" alt="">    
        </transition>
    </div>  
      <article class='text-content'>          
        <transition name="slide" appear>
            <div>
                <div>
                    <ImageTitle transparent :title="page.title"/>                     
                    <Editor @click="activateEditor" plugins="lists" api-key="2uyqbbnykquw7zpsd3ht43lzwe4s4dyu3l8x6p7vgvx8v4ug" :init="{menubar:false}" toolbar='[ undo redo | bold italic underline | fontsize forecolor | bullist numlist link ]' v-model="copy" class="copy" v-if="copy || $store.state.devMode" inline :disabled="!editorFocused" @change="updateContent"/>
                </div>                   
            </div>
        </transition> 
        <div class="linear-holder">
            <LinearButton :to="page?.previous" previous/>
            <LinearButton :to="page?.next"/>
        </div>     
      </article>            
      <transition name="fade">
        <Gallery :images="gallery" @close="galleryVisible = false" v-show="galleryVisible"/>
      </transition>
      <transition name="fade">
        <Gallery videoMode :images="[]" :videoId="videoId" @close="videoVisible = false" v-if="videoVisible" :show="videoVisible"/>
      </transition>
  </div>  
</template>

<script>
import Page from '@/mixins/Page';
import AttachmentUI from '@/components/AttachmentUI';
import LinearButton from '@/components/LinearButton';
import ImageTitle from '@/components/ImageTitle';
import Gallery from '@/components/Gallery';
export default {
    name:'Image Spotlight Alternate',
    mixins: [Page],
    props: {
        page:Object
    },
    components:{
        AttachmentUI,
        Gallery,
        LinearButton,
        ImageTitle
    },
    data() {
        return {
            galleryVisible:false,
            videoVisible:false,
            imagesFromGallery:0,
            copy:this.page.copy  
        }
    },
    computed: {     
        collage() {
            let images = [];            
            for (let index = 0; index < Math.min(this.imagesFromGallery, this.page.gallery?.length); index++) {
                const element = this.page.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        },  
        gallery() {
            let images = [];        
            for (let index = this.imagesFromGallery; index < this.page.gallery?.length; index++) {
                const element = this.page?.gallery[index];
                images.push({
                    file:element.file,
                    title:element.title
                });
            }
            return images;
        }
    }
}
</script>

<style lang="scss" scoped>
    @media (max-width:699px) {  
        .linear-holder {
            position: relative;
            height:90px;
        }
    }
    .template {
        display:grid;
        position: relative;         
        justify-content: flex-end;           
        background:url('~@/assets/images/bg_texture.jpg');
        background-size: cover;                
        box-sizing: border-box;                
        @media (min-width:900px) {
            gap:35px;
            grid-template-columns: minmax(70%, 930px)  minmax(30%, 540px);    
        }   
        @media print {
            grid-template-columns: minmax(70%, 930px)  minmax(30%, 540px);    
        } 
         
    }        
    .text-content {
        -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
        color-adjust: exact;     
        box-sizing: border-box;
        color: #181818;
        height:100%;
        display: inline-block;
        position: relative;    
        box-sizing: border-box;    
        background: url('~@/assets/images/bg_cars-white.jpg');
        background-color:#fff;
        background-size: cover;
        padding:60px;
        text-align: left;
        .title {             
            text-transform: uppercase;            
            padding:0;
            padding-bottom:20px;
            color: #181818;
        }    
        @media (min-width:900px) {         
            text-align: right;
        }
        .copy {
            margin-top:18px;
            margin-bottom: 40px;
        }                    
        @media print {
            > div {
                grid-template-columns: max-content 1fr;
                .copy {
                    padding-right:0;
                }
                div:not(.copy) {
                    margin-left:0;
                }
            }
        }                    
    }   
    .images {        
        display:grid;
        gap:1px;               
        height:100%;  
        min-height:35vh;
        position: relative;       
        grid-template-rows: 75px 1fr;   
        .ui {
            margin:20px 0;
            justify-content: flex-end;
            align-items: flex-end;
        }    
        > img {            
            width:100%;  
            @media (min-width:900px) {
                max-height: calc(100vh - 150px - 1px);
                object-fit: contain;
                object-position: top right;               
            }            
        }        
        @media (max-width:899px) {
            &:after {
                content:"";
                display:block;
                height:15px;
                width:100%;
                background-image:url('~@/assets/images/texture_black-asphalt.jpg');
                background-size: cover;            
                -webkit-print-color-adjust: exact;   /* Chrome, Safari, Edge */
                color-adjust: exact;     
            }       
        }
    }    
</style>